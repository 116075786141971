import React from "react";
// import Link from "./link";


const PageHeader = ({ title, name, image }) => {
  return (
    <section style={{ backgroundImage: `url(${image})` }} className="breadcrumb_section text-center">
      <div>
      {/* <ul className="breadcrumb">
        <li>
          <Link href="/">Home</Link>
        </li>
        <li>{name}</li>
      </ul> */}
      <h1>{title}</h1>
      </div>
    </section>
  );
};

export default PageHeader;
