import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "@/components/layout";
import MenuContextProvider from "@/context/menu-context";
import moment from "moment";
import HeaderOne from "@/components/header-one";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import Ferrara04 from "@/images/ferrara/ferrara_04.jpg";

export default function EventList({ data }) {
  const eventsPerPage = 4; // Number of events to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [events, setEvents] = useState(data.allSanityEvent?.nodes || []);
  
  useEffect(() => {
    const currentDateTime = new Date().toISOString();
    console.log(currentDateTime);
    let filteredEvents = [];
    for(let i = 0; i < events.length; i++){
      if(new Date(events[i].eventStart?.utc) > new Date(currentDateTime)&& events[i].hidden !== true){
        filteredEvents.push(events[i]);
      }
    }
    setEvents(filteredEvents);
    console.log(filteredEvents);
  }, []);
  
  
  const totalEvents = events.length;
  const totalPages = Math.ceil(totalEvents / eventsPerPage);
  const paginatedEvents = events.slice(
    (currentPage - 1) * eventsPerPage,
    currentPage * eventsPerPage
  );

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  return (
    <MenuContextProvider>
      <Layout PageTitle="Events">
        <HeaderOne />
        <PageHeader title="Events" image={Ferrara04} name="Events" />
        <div className="mb-8 container">
          <div className="py-4">
            {paginatedEvents.map((node) => (
              <div
                className="flex flex-sm-column flex-md-row flex-col mt-4 mb-[80px]"
                key={node.id}
              >
                <img
                  className="mr-4 align-self-center rounded-lg w-25 w-100-sm w-25-md h-25"
                  src={node.mainImage?.asset?.url || Ferrara04}
                  alt={node.title}
                />
                <div style={{ paddingLeft: "20px" }} className="media-body">
                  <div className="flex flex-row gap-2 text-sm text-gray-500">
                    <span>
                      <i className="far fa-calendar" />{" "}
                      {node.eventStart?.utc
                        ? moment(node.eventStart.utc).format("M/DD/YYYY")
                        : "No date available"}
                    </span>
                    <span>
                      <i className="far fa-clock" />{" "}
                      {node.allDay !== true
                        ? node.eventStart?.utc
                          ? moment(node.eventStart.utc).format("h:mma")
                          : "No time available"
                        : "All day"}
                    </span>
                    <span>
                      <i className="far fa-user-circle" />{" "}
                      {node.eventType || "No type available"} Event
                    </span>
                    {node.address && <span><i className="far fa-map" /> {node.address}</span>}
                    {node.zoomLink && (
                      <a href={node.zoomLink}>
                        <i className="fas fa-link" /> Link to event
                      </a>
                    )}
                  </div>
                  <h5 className="mt-3 mb-3">{node.title}</h5>
                  <br />
                  {node.slug && (
                    <a href={"/events/" + node.slug.current} className=" mt-3 btn-yellow">
                      Learn More
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="pagination">
            <div className="mb-8 container"><div className="py-4"></div>
              <div className="mt-8 flex w-full justify-items-center justify-center">
                <p className="text-gray-500">Showing <span className="font-medium"> {currentPage} </span> of <span className="font-medium">  {totalPages} </span> pages</p>
                </div>
              <div className="gap-2 flex w-full justify-items-center justify-center">
                  <button className="mr-6 disabled:text-slate-300" onClick={handlePrevPage} disabled={currentPage === 1}>
                ← Previous Page
                </button>
                <button className="ml-6 disabled:text-slate-300"  onClick={handleNextPage} disabled={currentPage === totalPages}>
                  Next Page →
                </button>
              </div>
              </div>

            </div>
          </div>
          <Footer />
        </Layout>
      </MenuContextProvider>
    );
}

export const query = graphql`
  query eventIndex3 {
    allSanityEvent(sort: { fields: [eventStart___utc], order: ASC }) {
      nodes {
        id
        _rawDescription
        mainImage {
          asset {
            url
          }
        }
        eventStart {
          utc
        }
        allDay
        eventEnd {
          utc
        }
        slug {
          current
        }
        zoomLink
        title
        eventType
        address
        hidden
      }
    }
  }
`;
